exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculate-js": () => import("./../../../src/pages/calculate.js" /* webpackChunkName: "component---src-pages-calculate-js" */),
  "component---src-pages-elbilsladdare-js": () => import("./../../../src/pages/elbilsladdare.js" /* webpackChunkName: "component---src-pages-elbilsladdare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontor-js": () => import("./../../../src/pages/kontor.js" /* webpackChunkName: "component---src-pages-kontor-js" */),
  "component---src-pages-produkter-js": () => import("./../../../src/pages/produkter.js" /* webpackChunkName: "component---src-pages-produkter-js" */),
  "component---src-pages-sa-funkar-det-js": () => import("./../../../src/pages/sa-funkar-det.js" /* webpackChunkName: "component---src-pages-sa-funkar-det-js" */),
  "component---src-pages-solceller-akersberga-js": () => import("./../../../src/pages/solceller-akersberga.js" /* webpackChunkName: "component---src-pages-solceller-akersberga-js" */),
  "component---src-pages-solceller-danderyd-js": () => import("./../../../src/pages/solceller-danderyd.js" /* webpackChunkName: "component---src-pages-solceller-danderyd-js" */),
  "component---src-pages-solceller-js": () => import("./../../../src/pages/solceller.js" /* webpackChunkName: "component---src-pages-solceller-js" */),
  "component---src-pages-solceller-norrkoping-js": () => import("./../../../src/pages/solceller-norrkoping.js" /* webpackChunkName: "component---src-pages-solceller-norrkoping-js" */),
  "component---src-pages-solceller-norrtalje-js": () => import("./../../../src/pages/solceller-norrtalje.js" /* webpackChunkName: "component---src-pages-solceller-norrtalje-js" */),
  "component---src-pages-solceller-sollentuna-js": () => import("./../../../src/pages/solceller-sollentuna.js" /* webpackChunkName: "component---src-pages-solceller-sollentuna-js" */),
  "component---src-pages-solceller-stockholm-js": () => import("./../../../src/pages/solceller-stockholm.js" /* webpackChunkName: "component---src-pages-solceller-stockholm-js" */),
  "component---src-pages-solceller-sundsvall-js": () => import("./../../../src/pages/solceller-sundsvall.js" /* webpackChunkName: "component---src-pages-solceller-sundsvall-js" */),
  "component---src-pages-solceller-taby-js": () => import("./../../../src/pages/solceller-taby.js" /* webpackChunkName: "component---src-pages-solceller-taby-js" */),
  "component---src-pages-solceller-timra-js": () => import("./../../../src/pages/solceller-timra.js" /* webpackChunkName: "component---src-pages-solceller-timra-js" */),
  "component---src-pages-solceller-vallentuna-js": () => import("./../../../src/pages/solceller-vallentuna.js" /* webpackChunkName: "component---src-pages-solceller-vallentuna-js" */),
  "component---src-pages-solcellsbatteri-js": () => import("./../../../src/pages/solcellsbatteri.js" /* webpackChunkName: "component---src-pages-solcellsbatteri-js" */),
  "component---src-pages-tack-js": () => import("./../../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */),
  "component---src-pages-vart-arbete-js": () => import("./../../../src/pages/vart-arbete.js" /* webpackChunkName: "component---src-pages-vart-arbete-js" */)
}

