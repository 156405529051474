import { Box } from "@mui/material";
import React from "react";
import NavBar from "./components/nav-bar";
import TopLayout from "./components/top-layout";
import theme from "./theme";
import BottomLayout from "./components/bottom-layout";

export default function wrapWithProvider({ element }) {
  return (
    <TopLayout theme={theme}>
      <NavBar />
      <Box sx={{ mt: { xs: 0, md: 0 } }}>{element}</Box>
      <BottomLayout />
    </TopLayout>
  );
}
