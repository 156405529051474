import { Box, Grid, Typography, Link, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function BottomLayout() {
  const link = {
    color: "#333",
  };

  return (
    <Box
      sx={{
        backgroundColor: "#efefef",
        paddingTop: "40px",
        paddingRight: "40px",
        paddingBottom: "40px",
        paddingLeft: "40px",
        marginTop: "30px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <StaticImage src="../images/logo.png" width={180}></StaticImage>
        </Grid>
        <Grid item>
          <Typography sx={{ marginTop: "10px" }}>
            <b>Folksol Stockholm AB</b> <br />
            559367-9748 <br />
            Vassvägen 3 187 69 TÄBY <br />
            Telefon: <a href="tel:08206718">08-20 67 18</a>
          </Typography>
        </Grid>

        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Grid container direction="column">
                <Link href="/solceller-danderyd">Solceller Danderyd</Link>
                <Link href="/solceller-norrtalje">Solceller Norrtälje</Link>
                <Link href="/solceller-sollentuna">Solceller Sollentuna</Link>
                <Link href="/solceller-stockholm">Solceller Stockholm</Link>
                <Link href="/solceller-taby">Solceller Täby</Link>
                <Link href="/solceller-vallentuna">Solceller Vallentuna</Link>
                <Link href="/solceller-akersberga">Solceller Åkersberga</Link>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Link href="/solceller-norrkoping">Solceller Norrköping</Link>
                <Link href="/solceller-timra">Solceller Timrå</Link>
                <Link href="/solceller-sundsvall">Solceller Sundsvall</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
